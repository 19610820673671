import { useGetMobileTokens } from "@sprint1/pkg/src/api/auth/client/mobileAuth/getMobileTokens";
import { PrimaryButton } from "@sprint1/pkg/src/button/Primary";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";

export function Mobile() {
  const tokensApi = useGetMobileTokens();
  const { translate } = useTranslation();

  return (
    <section style={{ height: "calc(100vh - var(--sprint1-header-height))" }}>
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div className="card shadow-2-strong">
              <div className="card-body p-5">
                <PrimaryButton
                  disabled={tokensApi.isRunning}
                  loading={tokensApi.isRunning}
                  onClick={async () => {
                    const { data } = await tokensApi.getMobileTokens();
                    //TODO check the domain name and iframe.
                    if (window.location.hostname.toLocaleLowerCase().endsWith("mdorthosystems.net")) {
                      //@ts-ignore see https://github.com/react-native-webview/react-native-webview/blob/master/docs/Guide.md#the-windowreactnativewebviewpostmessage-method-and-onmessage-prop
                      if (window?.ReactNativeWebView?.postMessage) {
                        //@ts-ignore
                        window.ReactNativeWebView.postMessage(
                          JSON.stringify({
                            type: "tokens",
                            payload: data,
                          })
                        );
                      }
                    }
                  }}
                >
                  {translate("__authorizeMobileApp")}
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
